import React, { useEffect, useState } from "react";
import { UserAuth } from "../context/userContext";
import Layout from '../components/layout';
import { 
    Typography, 
    Box, 
    Container, 
    // Divider, 
    TextField,
    // Select, 
    CircularProgress, 
    // InputLabel, 
    // FormControl, 
    // NativeSelect,
    // MenuItem, 
    Button} from "@mui/material";
// import InputLabel from '@mui/material/InputLabel';
import { useTheme } from '@mui/material/styles';
// import Upload from "../components/upload";
// import { FileUploadOutlined } from "@mui/icons-material";
import { storage, functions } from "../firebase/index";
import { ref, uploadBytesResumable, deleteObject } from "firebase/storage";
import { httpsCallable } from "firebase/functions";
// import { Link } from "react-router-dom";

function mola(file) {
    // const storage = getStorage();

    // Create a reference to the file to delete
    const desertRef = ref(storage, 'etabs/output/' + file);
    
    // Delete the file
    deleteObject(desertRef).then(() => {
        console.log(`${file} deleted successfully.`)
      // File deleted successfully
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
}

const handleDownload = async (fileUrl, fileName) => {
    console.log(fileUrl)
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); // Replace 'filename.extension' with the desired file name and extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};




export default function Etabs() {

    // const theme = useTheme()
    const { user } = UserAuth()

    // const [myAlert, setMyAlert] = useState([])
    const [loading, setLoading] = useState(false)
    const [exloading, setExLoading] = useState(false)
    const [runloading, setRunLoading] = useState(false)

    const [e2kFile, setE2kFile] = useState(null)
    const [exlFile, setExlFile] = useState(null)
    // const [outFiles, setOutFiles] = useState(null)

    // const [p1, setP1] = useState(null)

    const [step1, setStep1] = useState(false)
    const [step2, setStep2] = useState(true)
    const [step3, setStep3] = useState(true) 
    
    // const [mdUrl, setMdUrl] = useState('')

    const [step4a, setStep4a] = useState(true)
    const [step4b, setStep4b] = useState(true)
    const [step4c, setStep4c] = useState(true)
    const [step4d, setStep4d] = useState(true)

    const [dlSumFile, setDlSumFile] = useState(null)
    const [dlDebugFile, setDlDebugFile] = useState(null)
    const [dlCsvFile, setDlCsvFile] = useState(null)
    const [dlModFile, setDlModFile] = useState(null)

    const WallCheck = httpsCallable(functions, 'crackTest', {timeout: 580000})
    const dlResult = httpsCallable(functions, 'customerFace')

    const handleE2KFileChange = (e) => {
        // const reader = new FileReader(); 
        const file = e.target.files[0]
        setE2kFile(file)

        // reader.addEventListener(
        //     "load",
        //     () => {
        //       // this will then display a text file
        //       setP1(reader.result)
        //     //   console.log(reader.result);
        //     },
        //     false,
        //   );

        // if (file) {
        //     reader.readAsText(file);
        //   }

    }

    const handleEXLFileChange = (e) => { 
        setExlFile(e.target.files[0])
    }

    const handleE2KUpload = () => { 

        if (!e2kFile) return; 

        if (user) {

            const storageRef = ref(storage, `etabs/e2k/${e2kFile.name}`)
            const uploadTask = uploadBytesResumable(storageRef, e2kFile)
            uploadTask.on( 'state_changed', 
                (snapshot) => { 
                    // console.log(snapshot);
                    if (snapshot.bytesTransferred === snapshot.totalBytes) {
                        setLoading(false)
                        console.log('Upload complete');
                        // console.log(snapshot.task._blob.data_.name)
                        console.log(snapshot.task)
                        } else {
                            setLoading(true)
                            setStep1(true)
                            console.log('Upload uploading...');
                        }
                    },

                (error) => { console.error('Upload failed:', error) },
                // () => { console.log('Upload successful'); }); }
                )
            setStep2(false)
        }
        else {
            console.log('User not logged in')
        }

    }

    const handleEXLUpload = () => { 

        if (!exlFile) return; 

        if (user) {
            console.log(user)
            const storageRef = ref(storage, `etabs/exl/${exlFile.name}`)
            const uploadTask = uploadBytesResumable(storageRef, exlFile)
            uploadTask.on( 'state_changed', 
                (snapshot) => { 
                    // console.log(snapshot);
                    if (snapshot.bytesTransferred === snapshot.totalBytes) {
                        setExLoading(false)
                        // setStep2(false)
                        console.log('Upload complete');
                        setStep3(false)

                        // console.log(snapshot.task._blob.data_.name)
                        } else {
                            setExLoading(true)
                            setStep2(true)
                            console.log('EXLS uploading...');
                        }
                    },

            //     // if 
            //     // Optional: Handle progress updates if needed 
            //     // console.log('Upload is ' + (snapshot.bytesTransferred / snapshot.totalBytes) * 100 + '% done');
            //     // console.log(snapshot.task._blob.data_.name);
            //     }, 
                (error) => { console.error('Upload failed:', error); },
                // () => { console.log('Upload successful'); }); }
                )
            // setStep3(false)
        }
        else {
            console.log('User not logged in')
        }

    }

    useEffect(() => {

        console.log('HERE')

        return () => {}

    }, [loading])
    

    return (
        // <div>
        <Layout>
            <Container maxWidth='md'>
                <Box sx={{
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center', 
                    justifyContent: 'center',
                    width: '100%',
                    }}>

                    {/* Title Block */}
                    <Box sx={{
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        width: '100%',
                        // maxWidth: 'md',
                        }}>
                        <Typography 
                            textAlign={'center'} 
                            sx={{
                                fontSize: {xs: 36, md: 48}, 
                                fontWeight: 800, 
                                pt: '4rem',
                                width: '100%', 
                                height: '100%'}}>
                            ETABS Wall Crack Check
                        </Typography>
                    </Box>

                    {/* Etabs Input Card */}
                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center', 
                        justifyContent: 'center',
                        width: '100%',
                        border: 1,
                        borderRadius: '0.5rem',
                        mt: '2rem', 
                        backgroundColor: 'white',
                        }}>

                        <Box sx={{
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            width: '90%',
                            }}>

                            <Typography
                                textAlign={'center'} 
                                sx={{
                                    fontSize: {xs: 20, md: 24}, 
                                    fontWeight: 600, 
                                    pt: '1rem',
                                    pb: '1rem',
                                    width: '100%', 
                                    height: '100%'}}
                                >
                                1. Input your ETABS input (*.e2k) file                                        
                            </Typography>

                        </Box>

                        <Box sx={{
                            display: 'flex', 
                            alignItems: 'top', 
                            justifyContent: 'space-evenly',
                            width: '90%',
                            height: '100%',
                            pb: '1rem'
                            }}>

                            <TextField 
                                type='file' 
                                accept='application/octet-stream'
                                fullWidth 
                                onChange={handleE2KFileChange} 
                                size='small' 
                                inputProps={{ accept: ".e2k" }}
                                sx={{width: '60%'}}/>

                            <Box sx={{
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                width: '10%',
                                }}>
                                { 
                                    loading 
                                    ? 
                                    <CircularProgress size={'1.5rem'}/> 
                                    : 
                                    <></> 
                                }
                            </Box>

                            <Button 
                                disabled={step1} 
                                fullWidth 
                                variant='contained' 
                                disableElevation 
                                onClick={handleE2KUpload}
                                sx={{width: '20%'}}>
                                Submit
                            </Button>

                        </Box>

                    </Box>

                    {/* Excel Input Card */}
                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center', 
                        justifyContent: 'center',
                        width: '100%',
                        border: 1,
                        borderRadius: '0.5rem',
                        mt: '2rem', backgroundColor: 'white'
                        }}>
                            <Box sx={{
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    justifyContent: 'center',
                                    width: '90%',
                                }}>
                                <Typography
                                    textAlign={'center'} 
                                    sx={{
                                        fontSize: {xs: 20, md: 24}, 
                                        fontWeight: 600, 
                                        pt: '1rem',
                                        pb: '1rem',
                                        width: '100%', 
                                        height: '100%'}}
                                    >
                                    2. Input your ETABS Output (*.xlsx) file                                        
                                </Typography>
                            </Box>
                            {/* <Divider flexItem/> */}
                            <Box sx={{
                                    display: 'flex', 
                                    alignItems: 'top', 
                                    justifyContent: 'space-evenly',
                                    width: '90%',
                                    height: '100%',
                                    pb: '1rem'
                                }}>
                                    <TextField 
                                    type='file' 
                                    fullWidth 
                                    onChange={handleEXLFileChange}
                                    inputProps={{ accept: ".xlsx" }} 
                                    size='small' 
                                    sx={{width: '60%'}}/>
                                    <Box sx={{
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        width: '10%',
                                        }}>
                                        { 
                                            exloading 
                                            ? 
                                            <CircularProgress size={'1.5rem'}/> 
                                            : 
                                            <></> 
                                        }
                                    </Box>
                                    <Button 
                                    disabled={step2}
                                    fullWidth 
                                    variant='contained' 
                                    disableElevation
                                    onClick={handleEXLUpload} 
                                    sx={{width: '20%'}}>Submit</Button>

                            </Box>

                    </Box>

                    {/* Submission Button Card*/}
                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center', 
                        justifyContent: 'center',
                        width: '100%',
                        border: 1,
                        borderRadius: '0.5rem',
                        mt: '2rem', backgroundColor: 'white',
                        // mb: '2rem'
                        }}>

                        <Box sx={{
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                width: '90%',
                            }}>
                            <Typography
                                textAlign={'center'} 
                                sx={{
                                    fontSize: {xs: 20, md: 24}, 
                                    fontWeight: 600, 
                                    pt: '1rem',
                                    pb: '1rem',
                                    width: '100%', 
                                    height: '100%'}}
                                >
                                3. Modify the ETABS input file                                        
                            </Typography>
                        </Box>

                        <Box sx={{
                                display: 'flex', 
                                alignItems: 'top', 
                                justifyContent: 'space-evenly',
                                width: '90%',
                                height: '100%',
                                pb: '1rem'
                            }}>
                            <Button 
                                fullWidth 
                                variant='contained' 
                                disabled={step3}
                                disableElevation 
                                onClick={(e) => {
                                    const data = {
                                        'e2k': e2kFile.name, 
                                        'exl': exlFile.name, 
                                    }
                                    console.log(data)
                                    setStep3(true)
                                    setRunLoading(true)
                                    WallCheck(data)
                                    .then(response => {
                                        console.log(response)
                                        setRunLoading(false)
                                        const filesToRetrieve = response.data
                                        console.log(filesToRetrieve)

                                        setDlSumFile(filesToRetrieve.summaryLog)
                                        setDlDebugFile(filesToRetrieve.debug)
                                        setDlCsvFile(filesToRetrieve.csv)
                                        setDlModFile(filesToRetrieve.mod)
                                        setStep4a(false)                                        
                                        setStep4b(false)                                        
                                        setStep4c(false)                                        
                                        setStep4d(false)                                        
                                        // if (response.ok) {
                                        //     setRunLoading(false)
                                        //     const filesToRetrieve = response.data
                                        //     console.log(filesToRetrieve)
                                        //     setOutFiles(filesToRetrieve)
                                        //     setStep4a(true)
                                        // } else {
                                        //     console.log(response)
                                        // }
                                    })
                                }}
                                sx={{width: '25%'}}>
                                Modify
                            </Button> 
                            <Box sx={{
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                width: '10%',
                                }}>
                                { 
                                    runloading 
                                    ? 
                                    <CircularProgress size={'1.5rem'}/> 
                                    : 
                                    <></> 
                                }
                            </Box>
                        </Box>

                    </Box>

                    {/* Output Download Card */}
                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center', 
                        justifyContent: 'center',
                        width: '100%',
                        border: 1,
                        borderRadius: '0.5rem',
                        mt: '2rem', backgroundColor: 'white',
                        mb: '2rem'
                        }}>

                        <Box sx={{
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                width: '90%',
                            }}>
                            <Typography
                                textAlign={'center'} 
                                sx={{
                                    fontSize: {xs: 20, md: 24}, 
                                    fontWeight: 600, 
                                    pt: '1rem',
                                    pb: '1rem',
                                    width: '100%', 
                                    height: '100%'}}
                                >
                                4. Download Your Results                                        
                            </Typography>
                        </Box>

                        {/* <Divider flexItem variant='middle' sx={{pb: '2rem'}}/> */}

                        <Box sx={{
                                display: 'flex', 
                                flexDirection: 'column',
                                alignItems: 'center', 
                                justifyContent: 'center',
                                width: '90%',
                            }}>

                            <Box sx={{
                                display: 'flex',
                                width: '90%', 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                mb: '2rem',
                                }}>
                                <Button 
                                    variant='contained'
                                    disabled={step4a}
                                    fullWidth 
                                    disableElevation
                                    onClick={(e)=> {
                                        const data = {'name': dlSumFile}
                                        dlResult(data)
                                        .then((res) => {
                                            const url = res.data.url
                                            console.log(url, dlSumFile)
                                            handleDownload(url, dlSumFile)
                                            setStep4a(true)
                                            mola(dlSumFile)
                                        })
                                    }}
                                    sx={{width: '50%'}}
                                    >
                                    Summary Log File (.md)
                                </Button>
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                width: '90%', 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                mb: '2rem',
                                }}>
                                <Button 
                                    variant='contained'
                                    disableElevation
                                    disabled={step4b}
                                    fullWidth 
                                    onClick={(e)=> {
                                        const data = {'name': dlDebugFile}
                                        dlResult(data)
                                        .then((res) => {
                                            const url = res.data.url
                                            handleDownload(url, dlDebugFile)
                                            setStep4b(true)
                                            mola(dlDebugFile)
                                            // setStep4c(false)
                                        })
                                    }}
                                    sx={{width: '50%'}}
                                    >
                                    Debug File (.txt)
                                </Button>
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                width: '90%', 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                mb: '2rem',
                                }}>
                                <Button 
                                    variant='contained'
                                    disableElevation
                                    disabled={step4c}
                                    fullWidth 
                                    onClick={(e)=> {
                                        const data = {'name': dlCsvFile}
                                        dlResult(data)
                                        .then((res) => {
                                            const url = res.data.url
                                            handleDownload(url)
                                            setStep4c(true)
                                            mola(dlCsvFile)
                                        })
                                    }}
                                    sx={{width: '50%'}}
                                    >
                                    S22 Modifier (.csv)
                                </Button>
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                width: '90%', 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                mb: '2rem',
                                }}>
                                <Button 
                                    variant='contained'
                                    disableElevation
                                    disabled={step4d}
                                    fullWidth 
                                    onClick={(e)=> {
                                        const data = {'name': dlModFile}
                                        dlResult(data)
                                        .then((res) => {
                                            const url = res.data.url
                                            handleDownload(url)
                                            setStep4d(true)
                                            mola(dlModFile)
                                        })
                                    }}
                                    sx={{width: '50%'}}
                                    >
                                    Modified ETABS File (.e2k)
                                </Button>
                            </Box>


                        </Box>

                    </Box>

                </Box>

            </Container>
        </Layout>
        // </div>
    )
}