import React, { useEffect, useState } from "react";
import { UserAuth } from "../context/userContext";
import Layout from '../components/layout';
import { Typography, Box, Container, Divider, Select, CircularProgress, InputLabel, FormControl, NativeSelect, MenuItem } from "@mui/material";
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
// import NativeSelect from '@mui/material/NativeSelect';
// // import { alpha } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTheme } from '@mui/material/styles';
// // import theme from "../theme/theme";
// import { httpsCallable } from "firebase/functions";
import { db, payments, functions } from "../firebase/index";
import { Timestamp } from "firebase/firestore"
// // import { getCurrentUserSubscriptions, getCurrentUserPayments } from "@stripe/firestore-stripe-payments";
// import { getCurrentUserPayments, getCurrentUserSubscriptions } from "@invertase/firestore-stripe-payments";
// // import { grey } from "@mui/material/colors";

// // import { buttonColors, contrastTextColors } from '../theme/colors';
// // import ManagementBlock from "../components/managementBlock";
// // import ActiveSubBlock from "../components/activeSubBlock";
// // import Subscriptions from "../components/subscriptions";
import { doc, getDoc, getDocs, collection, query, where, orderBy, limit } from "firebase/firestore";

async function trades(user, sector) {
    
    const sectorCol = "Trade_" + sector
    // console.log(sectorCol)
    // const date = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });
    // const Year = date.slice(6, 10)
    // const Day = date.slice(3, 5)
    // const Mon = date.slice(0, 2)
    let docId;
    const q = query(collection(db, sectorCol), orderBy("timestamp", "desc"), limit(1))
    const snapShot = await getDocs(q)
    snapShot.forEach((doc) => {
        docId = doc.id
        // console.log(docId)
        });
    const colRef = collection(db, sectorCol, docId, "Runs")
    const lastEntry = query(colRef, orderBy("timestamp", "desc"), limit(1))
    const querySnapshot = await getDocs(lastEntry)
    const lastTrends = []
    querySnapshot.forEach((doc) => {
        lastTrends.push(doc.data())
      });
    
    return lastTrends
}

function createData(
    key, values
  ) {
    const time = new Timestamp(values.lastCrossing/1e9,0).toDate().toLocaleDateString('en-US', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: "2-digit",
        minute: 'numeric',
      })
    return { key, values, time };
  }

export default function Opportinities() {

    const theme = useTheme()
    const { user } = UserAuth()

    const [mypad, setMypad] = useState([])
    const [loading, setLoading] = useState(true)
    const [sector, setSector] = useState('Util')

    const handleSectorChange = (event) => {
        setSector(event.target.value);
    };

    useEffect(() => {

        if (user) {

            // 
            trades(user, sector)
            .then(
                res => {
                    const payload = res[0]
                    // console.log(payload)
                    delete payload.timestamp
                    const rows = []
                    Object.keys(payload).map((key) => {
                        rows.push(createData(key, payload[key]))
                        // console.log(createData(key, payload[key]))
                    })
                    setMypad(rows)
                    setLoading(false)
                }
            )
            .catch( (error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('Error code: ', errorCode)
                console.log('Error Msg.: ', errorMessage)
            })

        } else {
            console.log('HERE is the else condition')            
        }

        return(() => {console.log('Stock page REFRESHED!')})

    }, [sector, user])

    return(
        <Layout>
            <Container maxWidth='lg' sx={{background: theme.palette.background.default}}>
                { 
                    loading ? 

                    // Loading wheel
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                    : 
                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center', 
                        justifyContent: 'center',
                        width: '100%',
                        }}>

                        {/* Title Block */}
                        <Box sx={{
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center'}}>
                            <Typography 
                                textAlign={'center'} 
                                sx={{
                                    fontSize: {xs: 36, md: 48}, 
                                    fontWeight: 800, 
                                    pt: '4rem',
                                    // pb: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                Stock Opportunities
                            </Typography>
                        </Box> 

                        {/* Sector Selector */}
                        <Box sx={{width: '60%', pt: '4rem', pb: '5rem'}}>
                            <FormControl fullWidth>
                                <InputLabel variant='outlined' htmlFor="uncontrolled-native">
                                    Sector
                                </InputLabel>
                                <NativeSelect
                                    value={sector}
                                    onChange={handleSectorChange}
                                    inputProps={{
                                        name: 'Sector',
                                        id: 'uncontrolled-native',
                                    }}>
                                <option value={'Util'}>
                                    <Typography sx={{pl: '1rem'}}>
                                        Utilities
                                    </Typography>
                                </option>
                                <option value={'BaMa'}>Basic Materials</option>
                                {/* <option value={'Engy'}>Energy</option> */}
                                <option value={'Tech'}>Technology</option>
                                <option value={'CoSe'}>Communication Services</option>
                                </NativeSelect>
                            </FormControl>
                        </Box>

                        {/* Table Element */}
                        <Box sx={{
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems: 'center', 
                            justifyContent: 'center',
                            width: '90%',
                            // height: '20rem',
                            mb: '5rem',
                            }}>
                            <TableContainer 
                            sx={{border: 1, borderRadius: '0.5rem'}}
                            // component={Paper}
                            >
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow selected={true}>
                                        <TableCell  sx={{fontWeight: 900}}>Company Name</TableCell>
                                        <TableCell variant='head' align="right" sx={{fontWeight: 900}}>Symbol</TableCell>
                                        <TableCell align="right" sx={{fontWeight: 900}}>Price&nbsp;($)</TableCell>
                                        <TableCell align="right" sx={{fontWeight: 900}}>Crossing Time</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {mypad.map((row) => (
                                        <TableRow
                                        key={row.values.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            {row.values.name}
                                        </TableCell>
                                        <TableCell align="right">{row.key}</TableCell>
                                        <TableCell align="right">{row.values.priceCrossing}</TableCell>
                                        <TableCell align="right">{row.time}</TableCell>
                                        {/* <TableCell align="right">{row.protein}</TableCell> */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                        </Box>

                    </Box>
                }
            </Container>
        </Layout>
    )
}